<template>
  <b-tooltip
    triggers="hover"
    :target="target"
    :title="title"
    :placement="placement"
    :delay="{ show: 0, hide: 0 }"
  />
</template>

<script>
import { BTooltip } from 'bootstrap-vue'

export default {
  components: {
    BTooltip,
  },
  props: {
    target: {
      type: String,
      default: '',
    },
    placement: {
      type: String,
      default: 'topleft',
    },
    title: {
      type: String,
      default: '',
    },
  },
}
</script>
